<template>
  <div class="fire" :class="{ flip: flip, hideSmall: hideSmall }">
    <div class="blur">
      <div class="fire__flame_big"></div>
    </div>
    <div class="sparks">
      <main class="fire__spark"></main>
      <main class="fire__spark"></main>
      <main class="fire__spark"></main>
      <main class="fire__spark"></main>
    </div>
    <div class="blur fix">
      <div class="fire__flame"></div>
    </div>
    <div class="fire__light"></div>
  </div>
</template>

<script>
export default {
  name: "FireAnimated",
  data() {
    return {};
  },
  props: {
    flip: Boolean,
    hideSmall: Boolean,
  },
};
</script>

<style lang="scss">
// Options
$black: #1b1e23;
$white: #f4eed7;
$feature: #4b9aaa;
$log: linear-gradient(#e66465, #5d5e55);
$fire: linear-gradient(
  0deg,
  rgba(236, 221, 66, 1) 10%,
  rgba(237, 174, 52, 1) 15%,
  rgba(237, 100, 52, 1) 50%,
  rgba(250, 71, 8, 1) 59%
);
// Pen specific

/* html,
  body {
    height: 100%;
  }
  body {
    background: $black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: sans-serif;
  } */

.fire {
  max-width: 100px;
  transform: scale(0.4, 0.4);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  &__flame,
  &__flame_big {
    height: 250px;
    width: 180px;
    background: $fire;
    position: relative;
    margin-bottom: -15px;

    z-index: 3;
    opacity: 0.6;
  }
  &__flame {
    width: 350px;
    margin-left: 10px;
    animation: burn 1.5s linear forwards infinite;
  }
  &__flame_big {
    margin-bottom: -5px;
    z-index: -1;
    opacity: 1;
    animation: burn_alt 2.5s linear forwards infinite;
  }
  section:nth-of-type(1) {
    left: 70px;
    transform: rotateZ(45deg);
  }
  section:nth-of-type(2) {
    left: 100px;
    transform: rotateZ(25deg);
  }
  section:nth-of-type(3) {
    left: 140px;
    transform: rotateZ(5deg);
  }
  section:nth-of-type(4) {
    left: 140px;
    transform: rotateZ(-5deg);
  }
  section:nth-of-type(5) {
    left: 170px;
    transform: rotateZ(-15deg);
  }
  section:nth-of-type(6) {
    left: 200px;
    transform: rotateZ(-35deg);
  }
  section:nth-of-type(7) {
    left: 230px;
    transform: rotateZ(-45deg);
  }

  &__spark {
    position: absolute;
    height: 4px;
    width: 4px;
    border-radius: 1px;
    top: 0;
    left: 0;
    filter: blur(1px);
    background: yellow;
    z-index: 10;
    opacity: 0;
  }

  main:nth-of-type(1) {
    animation: spark_1 1s forwards infinite;
  }
  main:nth-of-type(2) {
    animation: spark_2 1s 0.75s forwards infinite;
  }

  main:nth-of-type(3) {
    animation: spark_3 1s 0.25s forwards infinite;
  }

  main:nth-of-type(4) {
    animation: spark_4 1s 0.5s forwards infinite;
  }

  &__light {
    height: 100%;
    width: 100%;
    border-radius: 50% 50% 30% 30%;
    transform: scale(1.1, 1.2);
    filter: blur(80px);
    background: orange;
    position: absolute;
    top: 0px;
    left: 0;
    z-index: -1;
    opacity: 0.2;
  }

  .blur {
    filter: blur(1px);
    &.fix {
      position: absolute;
    }
  }

  .sparks {
    position: absolute;
    left: 0;
    top: 0;
    margin-left: -100%;
  }

  @media screen and (max-width: 991px) {
    transform: scale(0.3, 0.3);
  }
  @media screen and (max-width: 767px) {
    max-width: 50px;
    transform: scale(0.2, 0.2);
  }
}

.flip {
  transform: scale(-0.4, 0.4);
  @media screen and (max-width: 991px) {
    transform: scale(-0.3, 0.3);
  }
  @media screen and (max-width: 767px) {
    transform: scale(-0.2, 0.2);
  }
}

@media screen and (max-width: 767px) {
  .hideSmall {
    display: none;
  }
}

@keyframes burn {
  0% {
    clip-path: polygon(
      48% 97%,
      42% 97%,
      37% 93%,
      31% 92%,
      28% 88%,
      26% 81%,
      29% 84%,
      34% 84%,
      33% 79%,
      30% 74%,
      31% 67%,
      34% 57%,
      34% 65%,
      39% 71%,
      43% 65%,
      43% 55%,
      40% 45%,
      48% 59%,
      49% 69%,
      51% 76%,
      55% 71%,
      54% 65%,
      54% 58%,
      58% 64%,
      61% 72%,
      57% 92%,
      61% 97%,
      64% 98%,
      66% 95%,
      64% 93%,
      57% 96%,
      54% 93%,
      48% 97%
    );
  }
  25% {
    clip-path: polygon(
      49% 97%,
      41% 97%,
      35% 92%,
      33% 86%,
      34% 80%,
      30% 74%,
      34% 77%,
      38% 81%,
      38% 78%,
      36% 72%,
      35% 67%,
      37% 61%,
      37% 54%,
      39% 61%,
      39% 67%,
      43% 63%,
      43% 58%,
      45% 44%,
      44% 58%,
      48% 66%,
      51% 67%,
      51% 59%,
      54% 67%,
      56% 72%,
      57% 79%,
      59% 77%,
      60% 71%,
      61% 77%,
      61% 83%,
      60% 89%,
      61% 94%,
      57% 97%,
      52% 98%
    );
  }
  50% {
    clip-path: polygon(
      46% 97%,
      39% 96%,
      35% 89%,
      36% 84%,
      34% 77%,
      30% 73%,
      30% 65%,
      30% 70%,
      35% 75%,
      38% 68%,
      37% 61%,
      40% 53%,
      41% 42%,
      42% 56%,
      44% 65%,
      50% 67%,
      51% 57%,
      53% 68%,
      52% 74%,
      51% 81%,
      55% 78%,
      57% 72%,
      58% 79%,
      57% 85%,
      55% 88%,
      60% 87%,
      63% 82%,
      63% 89%,
      59% 94%,
      55% 98%,
      51% 92%,
      50% 99%,
      45% 96%
    );
  }

  75% {
    clip-path: polygon(
      45% 97%,
      38% 97%,
      33% 93%,
      31% 87%,
      31% 81%,
      29% 76%,
      25% 69%,
      29% 61%,
      30% 69%,
      35% 71%,
      35% 62%,
      34% 54%,
      38% 45%,
      38% 54%,
      43% 62%,
      47% 57%,
      48% 49%,
      44% 38%,
      50% 46%,
      53% 60%,
      54% 71%,
      53% 79%,
      59% 76%,
      60% 66%,
      64% 73%,
      63% 79%,
      59% 85%,
      64% 90%,
      68% 84%,
      68% 92%,
      60% 97%,
      53% 98%,
      48% 99%
    );
  }
  100% {
    clip-path: polygon(
      48% 97%,
      42% 97%,
      37% 93%,
      31% 92%,
      28% 88%,
      26% 81%,
      29% 84%,
      34% 84%,
      33% 79%,
      30% 74%,
      31% 67%,
      34% 57%,
      34% 65%,
      39% 71%,
      43% 65%,
      43% 55%,
      40% 45%,
      48% 59%,
      49% 69%,
      51% 76%,
      55% 71%,
      54% 65%,
      54% 58%,
      58% 64%,
      61% 72%,
      57% 92%,
      61% 97%,
      64% 98%,
      66% 95%,
      64% 93%,
      57% 96%,
      54% 93%,
      48% 97%
    );
  }
}

@keyframes burn_alt {
  0%,
  100% {
    clip-path: polygon(
      48% 97%,
      43% 97%,
      38% 97%,
      34% 94%,
      33% 91%,
      32% 87%,
      29% 83%,
      26% 80%,
      21% 75%,
      20% 71%,
      20% 66%,
      20% 59%,
      20% 65%,
      24% 68%,
      28% 67%,
      28% 62%,
      25% 60%,
      21% 52%,
      21% 43%,
      24% 32%,
      23% 39%,
      24% 46%,
      28% 48%,
      33% 44%,
      33% 39%,
      31% 32%,
      28% 23%,
      30% 14%,
      31% 22%,
      35% 28%,
      39% 28%,
      41% 25%,
      40% 21%,
      39% 13%,
      41% 6%,
      42% 15%,
      45% 23%,
      49% 25%,
      52% 22%,
      51% 13%,
      54% 21%,
      56% 29%,
      53% 35%,
      50% 41%,
      53% 46%,
      58% 46%,
      60% 39%,
      60% 34%,
      64% 39%,
      65% 45%,
      63% 51%,
      61% 56%,
      64% 61%,
      68% 59%,
      71% 55%,
      73% 48%,
      73% 40%,
      76% 48%,
      77% 56%,
      76% 62%,
      74% 66%,
      69% 71%,
      71% 74%,
      75% 74%,
      79% 71%,
      81% 65%,
      82% 72%,
      81% 77%,
      77% 82%,
      73% 86%,
      73% 89%,
      78% 89%,
      82% 85%,
      81% 91%,
      78% 95%,
      72% 97%,
      65% 98%,
      59% 98%,
      53% 99%,
      47% 97%
    );
  }
  10% {
    clip-path: polygon(
      44% 100%,
      39% 100%,
      35% 99%,
      32% 99%,
      32% 99%,
      27% 97%,
      24% 95%,
      21% 92%,
      19% 89%,
      18% 86%,
      16% 83%,
      16% 79%,
      17% 73%,
      18% 68%,
      17% 64%,
      16% 64%,
      12% 60%,
      13% 52%,
      16% 45%,
      18% 40%,
      17% 47%,
      17% 53%,
      21% 57%,
      26% 59%,
      29% 56%,
      30% 48%,
      28% 45%,
      25% 38%,
      25% 33%,
      27% 23%,
      29% 15%,
      28% 23%,
      29% 31%,
      32% 38%,
      36% 49%,
      39% 52%,
      40% 59%,
      41% 64%,
      47% 66%,
      52% 64%,
      50% 56%,
      47% 48%,
      47% 42%,
      51% 34%,
      53% 29%,
      51% 40%,
      53% 46%,
      55% 52%,
      59% 54%,
      62% 53%,
      64% 49%,
      63% 43%,
      67% 46%,
      68% 54%,
      67% 60%,
      64% 65%,
      63% 71%,
      66% 75%,
      71% 77%,
      75% 75%,
      76% 68%,
      78% 72%,
      79% 76%,
      77% 80%,
      73% 82%,
      72% 86%,
      77% 88%,
      82% 87%,
      84% 81%,
      84% 88%,
      83% 92%,
      77% 96%,
      73% 99%,
      68% 99%,
      62% 98%,
      57% 100%,
      53% 100%,
      53% 95%,
      50% 94%,
      44% 93%
    );
  }
  20% {
    clip-path: polygon(
      44% 99%,
      41% 99%,
      35% 98%,
      29% 97%,
      24% 93%,
      21% 86%,
      20% 80%,
      16% 74%,
      16% 64%,
      16% 71%,
      21% 75%,
      25% 72%,
      25% 65%,
      22% 59%,
      19% 53%,
      19% 44%,
      21% 52%,
      25% 59%,
      29% 57%,
      29% 51%,
      26% 44%,
      26% 38%,
      30% 32%,
      31% 26%,
      30% 18%,
      34% 25%,
      33% 35%,
      33% 44%,
      34% 50%,
      39% 53%,
      44% 52%,
      45% 49%,
      44% 44%,
      42% 38%,
      44% 33%,
      48% 26%,
      45% 35%,
      47% 41%,
      50% 44%,
      51% 52%,
      49% 60%,
      48% 65%,
      53% 69%,
      58% 65%,
      57% 59%,
      58% 51%,
      62% 41%,
      66% 40%,
      64% 47%,
      61% 58%,
      63% 66%,
      66% 68%,
      70% 67%,
      72% 62%,
      73% 57%,
      71% 48%,
      75% 53%,
      79% 57%,
      79% 64%,
      76% 70%,
      72% 75%,
      70% 78%,
      74% 80%,
      78% 79%,
      82% 76%,
      84% 71%,
      85% 66%,
      84% 62%,
      88% 67%,
      89% 72%,
      89% 79%,
      87% 83%,
      84% 89%,
      81% 93%,
      76% 97%,
      69% 98%,
      60% 99%,
      54% 99%,
      48% 100%,
      45% 97%
    );
  }
  30% {
    clip-path: polygon(
      38% 100%,
      34% 99%,
      28% 96%,
      25% 93%,
      23% 89%,
      19% 85%,
      18% 79%,
      21% 74%,
      24% 68%,
      24% 62%,
      22% 55%,
      20% 50%,
      24% 53%,
      28% 61%,
      31% 68%,
      36% 68%,
      38% 69%,
      43% 67%,
      41% 59%,
      35% 54%,
      33% 46%,
      35% 39%,
      41% 32%,
      41% 26%,
      38% 17%,
      42% 21%,
      46% 29%,
      46% 39%,
      43% 45%,
      43% 51%,
      47% 55%,
      52% 56%,
      55% 54%,
      55% 49%,
      54% 42%,
      52% 34%,
      59% 43%,
      61% 51%,
      60% 58%,
      58% 63%,
      65% 68%,
      69% 67%,
      72% 63%,
      72% 57%,
      71% 51%,
      76% 39%,
      73% 50%,
      77% 56%,
      81% 55%,
      82% 49%,
      84% 59%,
      79% 69%,
      72% 74%,
      69% 76%,
      71% 81%,
      77% 81%,
      81% 77%,
      84% 70%,
      87% 75%,
      86% 81%,
      84% 84%,
      81% 87%,
      81% 91%,
      79% 94%,
      77% 96%,
      74% 97%,
      71% 95%,
      68% 96%,
      67% 99%,
      63% 100%,
      60% 100%,
      55% 100%,
      53% 100%,
      50% 100%,
      48% 100%,
      46% 100%,
      44% 100%,
      44% 100%,
      42% 100%,
      41% 100%
    );
  }
  40% {
    clip-path: polygon(
      45% 99%,
      40% 98%,
      34% 98%,
      31% 96%,
      28% 93%,
      26% 89%,
      27% 84%,
      26% 81%,
      23% 77%,
      20% 73%,
      18% 70%,
      19% 65%,
      19% 60%,
      20% 53%,
      20% 43%,
      24% 41%,
      28% 32%,
      28% 40%,
      28% 48%,
      29% 53%,
      33% 52%,
      35% 49%,
      36% 42%,
      36% 35%,
      36% 27%,
      39% 19%,
      42% 12%,
      40% 23%,
      39% 29%,
      41% 37%,
      43% 41%,
      44% 47%,
      45% 52%,
      47% 55%,
      50% 57%,
      52% 54%,
      53% 48%,
      52% 42%,
      51% 33%,
      50% 26%,
      54% 36%,
      55% 39%,
      57% 46%,
      57% 52%,
      55% 58%,
      55% 61%,
      58% 65%,
      62% 64%,
      64% 60%,
      65% 54%,
      64% 49%,
      65% 43%,
      68% 38%,
      67% 44%,
      69% 51%,
      72% 53%,
      72% 59%,
      70% 65%,
      68% 69%,
      68% 74%,
      71% 75%,
      74% 73%,
      76% 69%,
      78% 63%,
      82% 58%,
      81% 63%,
      81% 69%,
      81% 75%,
      76% 80%,
      75% 85%,
      79% 87%,
      82% 84%,
      83% 91%,
      79% 94%,
      75% 96%,
      71% 97%,
      64% 98%,
      58% 99%,
      53% 98%,
      46% 100%
    );
  }
  50% {
    clip-path: polygon(
      40% 99%,
      35% 99%,
      31% 98%,
      25% 95%,
      23% 92%,
      21% 88%,
      20% 83%,
      21% 80%,
      22% 76%,
      21% 70%,
      20% 68%,
      18% 64%,
      21% 68%,
      23% 71%,
      25% 75%,
      26% 79%,
      31% 84%,
      31% 79%,
      28% 69%,
      23% 60%,
      17% 53%,
      19% 41%,
      25% 31%,
      24% 24%,
      24% 19%,
      26% 24%,
      28% 30%,
      26% 37%,
      25% 44%,
      28% 49%,
      33% 50%,
      39% 48%,
      40% 43%,
      39% 35%,
      37% 29%,
      42% 34%,
      43% 40%,
      44% 47%,
      43% 53%,
      45% 59%,
      51% 60%,
      53% 56%,
      51% 49%,
      51% 44%,
      53% 36%,
      55% 32%,
      54% 22%,
      57% 29%,
      57% 44%,
      56% 50%,
      58% 56%,
      62% 59%,
      66% 59%,
      68% 53%,
      66% 45%,
      65% 38%,
      70% 44%,
      71% 50%,
      72% 55%,
      71% 62%,
      68% 66%,
      65% 70%,
      67% 74%,
      72% 74%,
      76% 71%,
      78% 65%,
      80% 68%,
      81% 73%,
      78% 81%,
      76% 89%,
      77% 93%,
      75% 97%,
      70% 98%,
      67% 98%,
      62% 98%,
      58% 98%,
      53% 99%,
      50% 99%,
      47% 99%,
      45% 99%
    );
  }
  60% {
    clip-path: polygon(
      45% 99%,
      41% 99%,
      35% 98%,
      30% 98%,
      25% 94%,
      22% 89%,
      21% 84%,
      23% 77%,
      23% 70%,
      19% 63%,
      23% 66%,
      27% 71%,
      28% 76%,
      32% 78%,
      35% 72%,
      32% 67%,
      28% 64%,
      24% 58%,
      24% 49%,
      27% 42%,
      30% 34%,
      31% 24%,
      29% 13%,
      33% 18%,
      38% 25%,
      38% 36%,
      37% 44%,
      41% 48%,
      45% 48%,
      48% 45%,
      48% 39%,
      46% 33%,
      48% 27%,
      52% 20%,
      50% 29%,
      51% 38%,
      53% 44%,
      54% 52%,
      56% 57%,
      61% 57%,
      64% 55%,
      65% 48%,
      63% 39%,
      63% 32%,
      66% 37%,
      69% 44%,
      70% 52%,
      68% 59%,
      66% 64%,
      67% 69%,
      73% 72%,
      76% 71%,
      77% 66%,
      76% 58%,
      76% 51%,
      80% 57%,
      82% 62%,
      82% 68%,
      80% 73%,
      77% 78%,
      74% 82%,
      75% 87%,
      78% 87%,
      81% 84%,
      84% 79%,
      86% 74%,
      88% 78%,
      87% 83%,
      84% 89%,
      82% 92%,
      78% 97%,
      74% 97%,
      69% 97%,
      66% 98%,
      61% 98%,
      57% 97%,
      53% 99%,
      49% 96%,
      47% 99%,
      48% 99%
    );
  }
  70% {
    clip-path: polygon(
      41% 99%,
      34% 99%,
      27% 98%,
      23% 96%,
      18% 94%,
      17% 90%,
      16% 85%,
      16% 81%,
      14% 75%,
      12% 68%,
      11% 62%,
      14% 67%,
      19% 72%,
      21% 69%,
      19% 62%,
      18% 57%,
      17% 54%,
      18% 49%,
      22% 43%,
      24% 36%,
      25% 30%,
      24% 23%,
      27% 24%,
      29% 33%,
      28% 39%,
      29% 48%,
      31% 53%,
      36% 53%,
      39% 47%,
      37% 41%,
      40% 43%,
      42% 49%,
      41% 55%,
      42% 57%,
      45% 59%,
      50% 60%,
      53% 55%,
      54% 49%,
      53% 44%,
      50% 39%,
      56% 42%,
      58% 47%,
      58% 56%,
      58% 59%,
      66% 61%,
      67% 58%,
      67% 51%,
      72% 46%,
      70% 55%,
      72% 59%,
      72% 64%,
      68% 69%,
      66% 74%,
      64% 80%,
      68% 82%,
      72% 78%,
      74% 73%,
      76% 77%,
      76% 80%,
      74% 83%,
      73% 87%,
      76% 89%,
      78% 88%,
      79% 85%,
      80% 82%,
      83% 86%,
      83% 90%,
      82% 92%,
      79% 94%,
      76% 96%,
      73% 99%,
      70% 98%,
      66% 98%,
      62% 99%,
      58% 100%,
      54% 100%,
      51% 100%,
      49% 100%,
      46% 100%,
      44% 100%
    );
  }
  80% {
    clip-path: polygon(
      47% 99%,
      42% 99%,
      37% 98%,
      32% 96%,
      28% 92%,
      26% 89%,
      26% 83%,
      26% 80%,
      26% 72%,
      23% 67%,
      16% 63%,
      14% 52%,
      16% 46%,
      16% 53%,
      20% 60%,
      26% 58%,
      27% 51%,
      25% 46%,
      20% 41%,
      19% 36%,
      19% 30%,
      21% 26%,
      24% 20%,
      23% 13%,
      22% 7%,
      26% 11%,
      28% 17%,
      28% 24%,
      26% 30%,
      30% 34%,
      34% 34%,
      39% 32%,
      40% 27%,
      38% 21%,
      43% 28%,
      43% 36%,
      41% 41%,
      46% 44%,
      51% 41%,
      53% 35%,
      53% 26%,
      57% 26%,
      59% 33%,
      60% 39%,
      57% 46%,
      55% 53%,
      58% 57%,
      64% 56%,
      66% 52%,
      69% 41%,
      70% 48%,
      69% 56%,
      66% 63%,
      64% 67%,
      65% 71%,
      70% 71%,
      74% 68%,
      76% 62%,
      77% 54%,
      79% 60%,
      81% 66%,
      80% 71%,
      76% 75%,
      72% 78%,
      71% 82%,
      75% 84%,
      80% 83%,
      84% 78%,
      86% 83%,
      83% 89%,
      78% 92%,
      74% 92%,
      73% 96%,
      69% 97%,
      65% 96%,
      62% 98%,
      57% 99%,
      54% 97%,
      51% 99%,
      46% 99%
    );
  }
  90% {
    clip-path: polygon(
      43% 99%,
      39% 99%,
      33% 99%,
      29% 98%,
      25% 97%,
      20% 94%,
      18% 91%,
      17% 87%,
      16% 82%,
      17% 78%,
      17% 73%,
      18% 65%,
      22% 61%,
      23% 56%,
      23% 51%,
      21% 46%,
      25% 51%,
      26% 59%,
      26% 64%,
      28% 65%,
      31% 65%,
      33% 63%,
      33% 59%,
      31% 54%,
      29% 49%,
      28% 43%,
      30% 37%,
      31% 30%,
      31% 24%,
      33% 28%,
      34% 33%,
      33% 39%,
      35% 46%,
      37% 50%,
      39% 55%,
      44% 54%,
      43% 49%,
      43% 44%,
      44% 39%,
      44% 33%,
      46% 38%,
      48% 42%,
      48% 47%,
      52% 49%,
      52% 51%,
      51% 57%,
      52% 60%,
      57% 59%,
      58% 56%,
      59% 50%,
      57% 42%,
      56% 38%,
      61% 44%,
      62% 47%,
      64% 53%,
      64% 57%,
      62% 61%,
      61% 66%,
      63% 69%,
      68% 69%,
      70% 67%,
      71% 64%,
      71% 58%,
      71% 53%,
      73% 49%,
      73% 55%,
      75% 58%,
      76% 63%,
      74% 68%,
      73% 73%,
      76% 78%,
      77% 81%,
      77% 85%,
      77% 90%,
      74% 94%,
      71% 97%,
      68% 99%,
      61% 99%,
      53% 100%,
      48% 100%
    );
  }
}

@keyframes spark_1 {
  0% {
    opacity: 1;
    left: 100px;
    top: 210px;
  }
  100% {
    top: 60px;
    left: 110px;
    opacity: 0;
  }
}

@keyframes spark_2 {
  0% {
    opacity: 1;
    left: 180px;
    top: 210px;
  }
  100% {
    top: 20px;
    left: 150px;
    opacity: 0;
  }
}

@keyframes spark_3 {
  0% {
    opacity: 1;
    left: 220px;
    top: 210px;
  }
  100% {
    top: 30px;
    left: 170px;
    opacity: 0;
  }
}

@keyframes spark_4 {
  0% {
    opacity: 1;
    left: 120px;
    top: 210px;
  }
  100% {
    top: 30px;
    left: 40px;
    opacity: 0;
  }
}
</style>
